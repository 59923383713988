<template>
    <div>
        <div class="page-title">
            <span>案例标签</span>
            <button class="btn" @click="newTag">新建标签</button>
        </div>

        <div class="search-tab">
            <at-input v-model="query.keywords" class="mb-4" width="500px" placeholder="按名称搜索">
                <button @click="getFirstList" class="btn">搜索</button>
            </at-input>
        </div>


        <a-table :columns="table.columns"
                 :rowKey="record => record.id || record.key"
                 :data-source="table.data"
                 :pagination="table.pagination"
                 @change="tableChanged"
                 :locale="{emptyText:'暂无案例'}"
                 class="members-table"
                 :loading="table.loading"
        >

            <div class="tag-title" slot="tag-title" slot-scope="title,item">
                <template v-if="item.status === 2">
                    <img class="hot-icon" src="/assets/icons/tag-hot.png" alt="">
                </template>
                <span>{{ title }}</span>
            </div>

            <div class="badges" slot="status" slot-scope="val,item">

                <div>
                    <template v-if="item.status >= 1 ">
                        <a-badge color="green" text="正常"/>
                    </template>
                    <template v-else-if="item.status === 0">
                        <a-badge color="orange" text="已禁用"/>
                    </template>
                </div>


                <div class="mt-4" v-if=" item.recommend === 1 ">
                    <a-badge color="lime" text="推荐"/>
                </div>
            </div>
            <div slot="op" slot-scope="val,item">

                <template v-if="item.status > 0">

                    <div class="mb-4 op-btn" @click="changeStatus(item,0)">
                        <a>禁用</a>
                    </div>
                    <div class="mb-4 op-btn" @click="changeStatus(item,item.status === 1 ? 2 : 1)">
                        <a>{{ item.status === 1 ? '设置热门' : '取消热门' }}</a>
                    </div>

                </template>
                <template v-else>

                    <div class="mb-4 op-btn" @click="changeStatus(item,1)">
                        <a>启用</a>
                    </div>

                </template>

                <div class="op-btn" @click="editTag(item)">
                    <a>编辑</a>
                </div>

            </div>

        </a-table>

        <a-modal v-model="showDetailModal" @ok="syncTag">

            <a-form-item label="标签名">
                <a-input v-model="editDetail.title"></a-input>
            </a-form-item>

            <a-form-item label="状态">

                <a-radio-group v-model="editDetail.status">
                    <a-radio-button :value="0">禁用</a-radio-button>
                    <a-radio-button :value="1">启用</a-radio-button>
                    <a-radio-button :value="2">热门</a-radio-button>
                </a-radio-group>

            </a-form-item>

        </a-modal>

    </div>
</template>

<script>

import api from "../../../repo/api";
import AtInput from "@/components/AtInput.vue";
import moment from "moment/moment";

export default {
    components: {AtInput},
    data() {
        return {
            routeMatch: "/ast/cases",
            query: {
                status: 1
            },
            detail: null,
            showDetailModal: false,
            editDetail: {
                title: "",
                status: 1,
            },
            table: {
                columns: [
                    {
                        title: 'ID',
                        dataIndex: 'id',
                        width: '110px',
                    },
                    {
                        title: '名称',
                        dataIndex: 'title',
                        scopedSlots: {customRender: 'tag-title'},
                    },
                    {
                        title: '状态',
                        dataIndex: 'status',
                        scopedSlots: {customRender: 'status'},
                    }, {
                        title: '操作',
                        dataIndex: 'op',
                        scopedSlots: {customRender: 'op'},
                    },

                ],
                loading: true,
                pagination: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 15,
                    showTotal: (total, range) => {
                        return `当前${range[0]}-${range[1]}，共 ${total} 个案例`;
                    },
                },
                data: []
            },

        }
    },
    mounted() {
        this.getFirstList()
    },
    methods: {
        formatTime(time) {
            return moment(time).format('yyyy-MM-DD HH:mm')
        },
        getFirstList() {
            this.query.page = 1
            this.getList()
        },
        getList() {

            this.table.loading = true

            api.get('/ast-cases/tags', this.query, (data) => {

                this.table.data = data.data.data
                this.table.pagination.current = data.data.current_page
                this.table.pagination.total = data.data.total
                this.table.pagination.pageSize = data.data.per_page

                this.table.loading = false

            })
        },
        tableChanged(pagination) {
            this.query.page = pagination.current
            this.getList()
        },
        changeStatus(item, status) {
            this.$loading.show()

            api.post('/ast-cases/change-tag-status', {id: item.id, val: status}, (res) => {

                this.$loading.hide()

                this.$message.data(res)

                if (res.code === 0) this.getList()
            })
        },
        newTag() {
            this.editDetail = {
                title: "",
                status: 1
            }

            this.showDetailModal = true
        },
        editTag(item) {
            this.editDetail = JSON.parse(JSON.stringify(item))

            this.showDetailModal = true
        },
        syncTag() {
            this.$loading.show()

            api.post('/ast-cases/sync-tag', this.editDetail, (res) => {

                this.$loading.hide()

                this.$message.data(res)

                if (res.code === 0) {
                    this.showDetailModal = false
                    this.getList()
                }
            })
        },
    }
}
</script>
<style lang="less" scoped>
.user-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    position: relative;

    .user-name {
        margin-bottom: 12px;
    }

    .avatar {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        margin-right: 16px;
    }

    .right-icon {
        height: 15px;
        position: absolute;
        right: 0;
        top: 6px;
    }
}

.members-table /deep/ table {
    .ant-table-thead > tr > th {
        background: transparent;
        padding: 12px 12px;
    }

    .ant-table-column-title {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
    }

    .ant-table-tbody > tr > td {
        font-size: 14px;
        font-weight: 400;
        color: #606266;
        line-height: 14px;
        padding: 12px 12px;
    }
}

.search-tab {
    display: flex;
    justify-content: space-between;
}

.sd-item {
    margin-top: 32px;

    &:first-child {
        margin-top: 0;
    }
}

.case-tag {
    font-weight: 400;
    font-size: 12px;
    color: #DF8C71;
    line-height: 15px;
    text-align: left;
    font-style: normal;
    padding: 4px 7px;
    background: rgba(252, 182, 159, 0.1);
    border-radius: 5px;
    display: inline-block;
}

.op-btn {
    font-size: 12px;
}

.badges {
    font-size: 12px;

    /deep/ .ant-badge-status-text {
        font-size: 12px;
    }
}

.mt-4 {
    margin-top: 8px;
}

.review-title {
    display: block;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;

    .icon {
        margin-right: 12px;
        color: orange;
    }
}

.review-btns {
    display: flex;
    margin-right: -12px;
    margin-top: 12px;
    justify-content: flex-end;

    .review-btn {
        margin-right: 12px;
    }
}

.page-title {
    .btn {
        float: right;
    }
}

.tag-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.hot-icon {
    width: 15px;
    margin-right: 8px;
}


</style>